<template>
  <Page title="Galerie" color="error">
    <template slot="extension">
      <v-tabs background-color="error darken-1">
        <v-toolbar color="transparent" flat dense>
          <v-spacer />
          <v-text-field
            single-line
            dense
            hide-details
            label="Suche"
            v-model="search"
            append-icon="mdi-magnify"
            clearable
          ></v-text-field>
        </v-toolbar>
      </v-tabs>
    </template>
    <v-alert
      v-if="!search"
      type="info"
      text
      border="left"
      :prominent="!$vuetify.breakpoint.xs"
    >
      Die Sammlung umfasst {{ filteredItems.length }} Einträge.
    </v-alert>
    <v-alert
      v-if="search && filteredItems.length == 0"
      type="error"
      text
      border="left"
      :prominent="!$vuetify.breakpoint.xs"
    >
      Die Suche nach «{{ search }}» ergab keinen Treffer!
    </v-alert>
    <v-alert
      v-if="search && filteredItems.length > 0"
      type="success"
      text
      border="left"
      :prominent="!$vuetify.breakpoint.xs"
    >
      {{ filteredItems.length }} Suchergebnis<template
        v-if="filteredItems.length > 1"
        >se</template
      >
      für «{{ search }}».
    </v-alert>

    <v-row>
      <v-col
        v-for="(item, index) in filteredItems"
        :key="index"
        xl="2"
        lg="3"
        md="4"
        sm="6"
        cols="12"
        align-top
      >
        <v-card :to="{ name: 'Gallery', params: { gallery: item.name } }">
          <GalleryThumb
            :gallery="item.name"
            :image="item.image"
            class="white--text align-end"
          >
            <v-card-title>{{ item.title }} </v-card-title>

            <v-card-subtitle class="d-flex align-center justify-center"
              ><span
                ><template v-if="item.date"
                  >{{ formatDate(item.date, true) }},
                </template>
                {{ item.count }} Bilder</span
              ><v-spacer />
              <v-tooltip bottom>
                <template
                  v-slot:activator="{ on, attrs }"
                  v-if="item.visibility == 'teacher'"
                >
                  <v-icon color="white" v-bind="attrs" v-on="on"
                    >mdi-account-school</v-icon
                  >
                </template>
                <span>nur für LK sichtbar</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template
                  v-slot:activator="{ on, attrs }"
                  v-if="item.visibility == 'admin'"
                >
                  <v-icon color="white" v-bind="attrs" v-on="on"
                    >mdi-account-tie</v-icon
                  >
                </template>
                <span>nur für Admins sichtbar</span>
              </v-tooltip>
            </v-card-subtitle>
          </GalleryThumb>
        </v-card>
      </v-col>
    </v-row>
  </Page>
</template>

<script>
import { defineComponent } from "vue";
import GalleryThumb from "./components/GalleryThumb.vue";
import { formatDate } from "common/utils/date";
export default defineComponent({
  components: { GalleryThumb },
  data() {
    return {
      items: [],
      search: "",
    };
  },
  computed: {
    filteredItems() {
      return this.items.filter(
        (item) =>
          item.title.toLowerCase().includes(this.search.toLowerCase()) ||
          (item.description &&
            item.description.toLowerCase().includes(this.search.toLowerCase()))
      );
    },
  },
  methods: {
    formatDate,
    async fetchData() {
      const galleries = await this.apiList({
        resource: "gallery/gallery",
        query: ``,
      });
      this.items = galleries.sort((a, b) =>
        a.date > b.date ? -1 : a.date < b.date ? 1 : 0
      );
    },
  },
  async created() {
    this.fetchData();
  },
});
</script>
